(function(moment) {
	var STRINGS = {
		nodiff: '',
		years: 'yy',
		months: 'MM',
		days: 'dd',
		hours: 'hh',
		minutes: 'mm',
		seconds: 's',
		delimiter: ' '
	};
    moment.fn.preciseDiff = function(d2, withoutSuffix, length) {
        return moment.preciseDiff(this, d2, withoutSuffix, length);
    };
    moment.preciseDiff = function(d1, d2, withoutSuffix, length) {
        var m1 = moment(d1), m2 = moment(d2), langData = moment.localeData();
        if (m1.isSame(m2)) {
            return STRINGS.nodiff;
        }
        if (m1.isAfter(m2)) {
            var tmp = m1;
            m1 = m2;
            m2 = tmp;
        }
		if (withoutSuffix === undefined) {
			withoutSuffix = true;
		}

        var yDiff = m2.year() - m1.year();
        var mDiff = m2.month() - m1.month();
        var dDiff = m2.date() - m1.date();
        var hourDiff = m2.hour() - m1.hour();
        var minDiff = m2.minute() - m1.minute();
        var secDiff = m2.second() - m1.second();

        if (secDiff < 0) {
            secDiff = 60 + secDiff;
            minDiff--;
        }
        if (minDiff < 0) {
            minDiff = 60 + minDiff;
            hourDiff--;
        }
        if (hourDiff < 0) {
            hourDiff = 24 + hourDiff;
            dDiff--;
        }
        if (dDiff < 0) {
            var daysInLastFullMonth = moment(m2.year() + '-' + (m2.month() + 1), "YYYY-MM").subtract(1, 'months').daysInMonth();
            if (daysInLastFullMonth < m1.date()) { // 31/01 -> 2/03
                dDiff = daysInLastFullMonth + dDiff + (m1.date() - daysInLastFullMonth);
            } else {
                dDiff = daysInLastFullMonth + dDiff;
            }
            mDiff--;
        }
        if (mDiff < 0) {
            mDiff = 12 + mDiff;
            yDiff--;
        }


        function pluralize(num, key) {
			return langData.relativeTime(num, withoutSuffix, STRINGS[key]);
        }

        var result = [];

		function isResultFilled() {
			return length && result.length == length;
		}

        if (yDiff) {
            result.push(pluralize(yDiff, 'years'));
        }
        if (mDiff && !isResultFilled()) {
            result.push(pluralize(mDiff, 'months'));
        }
        if (dDiff && !isResultFilled()) {
            result.push(pluralize(dDiff, 'days'));
        }
        if (hourDiff && !isResultFilled()) {
            result.push(pluralize(hourDiff, 'hours'));
        }
        if (minDiff && !isResultFilled()) {
            result.push(pluralize(minDiff, 'minutes'));
        }
        if (secDiff && !isResultFilled()) {
            result.push(pluralize(secDiff, 'seconds'));
        }

        return result.join(STRINGS.delimiter);
    };
}(window.moment));
